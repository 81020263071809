import React from "react";
import { navContainerCss, navItemTextResponsiveCss } from "../Utility";
import { useDispatch, useSelector } from "react-redux";
import { trackOurOfferingsNavButtonClick } from "../../../Utility/GoogleAnalyticsActions";
import {
  toggleFace_Mask_Detection,
  toggleWork_Helmet_Detection,
} from "../../../features/nextGenProductsSlice";
// import { toggleNextGen_AiMl } from "../../../features/nextGenProductsSlice";

export default function NavItems() {
  const dispatch = useDispatch();

  const handleToggleForDispatch = (action) => {
    dispatch(action);
  };

  const face_Mask_Detection_Active = useSelector(
    (state) => state.setNextGenProducts
  ).face_Mask_Detection;

  const face_work_Helmet_Detection_Active = useSelector(
    (state) => state.setNextGenProducts
  ).work_Helmet_Detection;

  return (
    <div
      className={` our-Services-Nav-Control-Container absolute -top-16  h-16 md:h-20 md:-top-20
       flex  gap-x-1 sm:gap-x-1 ${navContainerCss()} overflow-x-auto `}
    >
      <button
        onClick={() =>
          handleToggleForDispatch(
            toggleFace_Mask_Detection(),
            trackOurOfferingsNavButtonClick("Our Services")
          )
        }
        className={` flex justify-center items-center px-2 sm:px-4 mx-auto  h-full rounded-t-lg cursor-pointer ${navItemTextResponsiveCss()} ${
          face_Mask_Detection_Active
            ? " text-Brand-Color border-t-[1px] border-x-[1px] border-[#7d8488] bg-[#151515] "
            : " text-white bg-[#242424]"
        } `}
      >
        <h1>Face mask detection</h1>
      </button>
      <button
        onClick={() =>
          handleToggleForDispatch(
            toggleWork_Helmet_Detection(),
            trackOurOfferingsNavButtonClick("Our Services")
          )
        }
        className={` flex justify-center items-center px-2 sm:px-4 mx-auto  h-full rounded-t-lg cursor-pointer ${navItemTextResponsiveCss()} ${
          face_work_Helmet_Detection_Active
            ? " text-Brand-Color border-t-[1px] border-x-[1px] border-[#7d8488] bg-[#151515] "
            : " text-white bg-[#242424]"
        } `}
      >
        <h1>Work helmet detection</h1>
      </button>
    </div>
  );
}
