import { lazy, Suspense } from "react";
import NavItems from "./NavItems";
import { CreateUniqId } from "../../../Utility";
import LoadingComponent from "../../helper/LoadingComponent";
import { useSelector } from "react-redux";

// * import method different because its a lazy load import
const Accordion = lazy(() => import(`./Accordion`));

export default function AccordionContainer() {
  // * ***Accessing setDisplayService State (Redux)***
  const nextGenProductsFromRedux = useSelector(
    (state) => state.setNextGenProducts
  )?.details;
  // * ***Accessing Night Mode State (Redux)***
  const borderColorWith_Shadow_xlDynamic = useSelector(
    (state) => state.setNightMode
  ).css.borderColorWith_Shadow_xl;

  return (
    <>
      <section className={` h-full `}>
        <div
          className={` group/Scrollbar relative border-[1px] space-y-4 h-fit w-full rounded-lg ${borderColorWith_Shadow_xlDynamic}`}
        >
          <NavItems />

          {/* Accordions container*/}
          <div className={` w-[90%]  mx-auto `}>
            {nextGenProductsFromRedux &&
              nextGenProductsFromRedux.map((element, Index) => (
                <Suspense
                  fallback={
                    <LoadingComponent
                      loadingMsgString="Processing Our Services List"
                      spinnerWidthString="w-16"
                      spinnerHeightString="h-16"
                    />
                  }
                  key={CreateUniqId(`OurServices-Accordion-Index-${Index}`)}
                >
                  <Accordion
                    titleString={element.title}
                    detailsString={element.details}
                    detailsListArray={element.detailsListArray}
                    hrefString={element.hrefLink}
                    accordionIndex={Index}
                  />
                </Suspense>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}
