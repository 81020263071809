import React, { Suspense, lazy, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom"; // Import the useLocation hook
import smoothScrollById from "../../Utility/smoothScrollById";
import DataJson from "../../Data.json";
import { updateNavbarClassBasedOnScroll } from "./helper";
import { scrollStart } from "../../Utility";
import LoadingComponent from "../helper/LoadingComponent";
import { trackNavBarClicks } from "../../Utility/GoogleAnalyticsActions";

// * import method different because its a lazy load import
const EnableDarkModeBtn = lazy(() => import(`./EnableDarkModeBtn`));
const NavbarToggler = lazy(() => import(`./NavbarToggler`));

const Navbar = () => {
  const [navIsOpen, setNavIsOpen] = useState(false);
  const location = useLocation(); // Use the useLocation hook

  const toggleNavbar = () => {
    setNavIsOpen(!navIsOpen);
  };

  const activeNavItem =
    "bg-[#ff8000] shadow-inner font-bold  border-white/70 saturate-150 ";

  useEffect(() => {
    // Attach the event listener when the component mounts
    window.addEventListener("scroll", updateNavbarClassBasedOnScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", updateNavbarClassBasedOnScroll);
    };
  }, []);

  return (
    <nav
      id="navbar"
      className={` navBar-Bg-Scrolled-Top0 to-black/70 w-full z-50 fixed top-0 left-0 ease-in-out duration-500`}
    >
      <div className="container mx-auto px-4 ">
        <div className="flex justify-between items-center py-4">
          <div className="flex items-center">
            <button
              onClick={() => scrollStart()}
              className="text-white font-bold text-xl"
            >
              <Link to="/">Desiify - AI-ML</Link>
            </button>
          </div>
          {/* Desktop menu */}
          <div className="hidden lg:visible lg:flex space-x-4">
            {DataJson?.navList?.map((element, index) => (
              <Link
                key={index}
                onClick={() => {
                  if (element?.id) {
                    smoothScrollById(element.id);
                  }
                  trackNavBarClicks();
                }}
                to={element?.href ? element.href : "#"}
                className={`text-white border hover:bg-[#ff8000]   tracking-widest px-3 py-2  rounded-lg  flex items-center justify-center ease-in duration-150 ${
                  // Add "active" class if the current URL matches the link's href or hash
                  location.pathname === element?.href ||
                  location.hash === `#${element?.id}`
                    ? activeNavItem
                    : " border-transparent "
                }`}
              >
                {element?.item ? element.item : "NAN"}
              </Link>
            ))}
            <Suspense
              fallback={
                <LoadingComponent
                  loadingMsgString="Loading..."
                  spinnerHeightString=" h-10 "
                  spinnerWidthString=" w-10 "
                />
              }
            >
              <EnableDarkModeBtn />
            </Suspense>
          </div>
          <div className="lg:hidden flex items-center space-x-1">
            <Suspense
              fallback={
                <LoadingComponent
                  loadingMsgString="Loading..."
                  spinnerHeightString=" h-10 "
                  spinnerWidthString=" w-10 "
                />
              }
            >
              <EnableDarkModeBtn />
            </Suspense>
            <Suspense
              fallback={
                <LoadingComponent
                  loadingMsgString="Loading..."
                  spinnerHeightString=" h-10 "
                  spinnerWidthString=" w-10 "
                />
              }
            >
              <NavbarToggler
                navIsOpen={navIsOpen}
                toggleNavbar={toggleNavbar}
              />
            </Suspense>
          </div>
        </div>
        {/* Mobile menu */}
        {navIsOpen && (
          <div className="lg:hidden pb-3 ">
            {DataJson?.navList?.map((element, index) => (
              <Link
                onClick={() => {
                  if (element?.id) {
                    smoothScrollById(element.id);
                  }
                  trackNavBarClicks();
                }}
                to={element?.href ? element.href : "#"}
                className={`text-white hover:bg-[#ff8000]  border  tracking-widest px-3 py-2  rounded-lg  flex items-center justify-center ease-in duration-150  ${
                  // Add "active" class if the current URL matches the link's href or hash
                  location.pathname === element?.href &&
                  location.hash === `#${element?.id}`
                    ? activeNavItem
                    : " border-transparent "
                }`}
              >
                {element?.item ? element.item : "NAN"}
              </Link>
            ))}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
