import React from "react";
import "./App.css";
import { BrowserRouter as Main, Routes, Route } from "react-router-dom";
import "remixicon/fonts/remixicon.css";
import { useSelector } from "react-redux";
import Footer from "./Components/Footer";
import HomePage from "./Pages/HomePage";
import LearnMore from "./Pages/LearnMore";
import ContactUsPage from "./Pages/ContactUsPage";
import ReactGA from "react-ga4";
import Navbar from "./Components/Navbar";
import DataJson from "./Data.json";
import NotFound404 from "./Components/Error/NotFound404";
// * Google analytics setup
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);

export function App() {
  const dynamicBodyBgColor = useSelector((state) => state.setNightMode).css
    .bodyBackGroundColor;
  document.body.style.background = dynamicBodyBgColor;
  return (
    <>
      <div className={` space-y-28`}>
        <Main>
          <Navbar />

          <Routes>
            <Route exact path="/" Component={HomePage}></Route>
            <Route
              exact
              path="/ContactUsPage"
              Component={ContactUsPage}
            ></Route>

            {/* Routes for learMore page's */}
            {DataJson?.Routes?.LearnMorePage.map((element, index) => (
              <Route
                key={index}
                exact
                path={`/learnMore/${element}`}
                Component={LearnMore}
              ></Route>
            ))}

            <Route path="*" Component={NotFound404}></Route>
          </Routes>
          <Footer />
        </Main>
      </div>
    </>
  );
}

export default App;
