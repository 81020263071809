import { createSlice } from "@reduxjs/toolkit";

import DataJson from "../Data.json";

export const nextGenProductsSlice = createSlice({
  name: "NextGenProducts",
  initialState: {
    face_Mask_Detection: true,
    work_Helmet_Detection: false,
    details: [DataJson.NextGenProducts.NextGen_AiMl[0]],
  },
  reducers: {
    toggleFace_Mask_Detection: (state) => {
      if (state.work_Helmet_Detection) {
        state.face_Mask_Detection = true;
        state.work_Helmet_Detection = false;
        state.details = [DataJson.NextGenProducts.NextGen_AiMl[0]];
      } else {
        state.face_Mask_Detection = true;
        state.details = [DataJson.NextGenProducts.NextGen_AiMl[0]];
      }
    },
    toggleWork_Helmet_Detection: (state) => {
      if (state.face_Mask_Detection) {
        state.work_Helmet_Detection = true;
        state.face_Mask_Detection = false;
        state.details = [DataJson.NextGenProducts.NextGen_AiMl[1]];
      } else {
        state.work_Helmet_Detection = true;
        state.details = [DataJson.NextGenProducts.NextGen_AiMl[1]];
      }
    },
  },
});
export const { toggleFace_Mask_Detection, toggleWork_Helmet_Detection } =
  nextGenProductsSlice.actions;

export default nextGenProductsSlice.reducer;
